import React, { useEffect, useRef, useState } from "react";

import { useCanvas } from "../../hooks/useCanvas";

import cartaoCejam from "../../assets/img/cartao-cejam.png";
import cartao1 from "../../assets/img/cartao1.png";
import cartao2 from "../../assets/img/cartao2.png";
// import cartao3 from "../../assets/img/cartao3.jpg";
// import cartao4 from "../../assets/img/cartao4.jpg";
import done from "../../assets/img/concluido.svg";
import iconRefazer from "../../assets/img/refazer.svg";
import Votar from "../Votar";
// import Snowfall from 'react-snowfall';

function Cartoes({ setPage }) {
  const messages = [
    [
      "Seja luz! É tempo de celebrar a vida, a cura e o cuidado ",
      "com o próximo. Desejamos um Natal e um Ano Novo ",
      "iluminados e cheios de saúde, para você e sua família.",
    ],
    [
      "Que este Natal seja uma fonte de luz, inspiração e ",
      "do querer bem e fazer o bem ao próximo. Celebre a paz, ",
      "o amor e a saúde. Boas festas!",
    ],
    [
      "Que neste Natal você continue sendo capaz de sentir, ",
      "de mudar e levar ternura, harmonia e saúde a todos ",
      "ao seu redor. Seja a estrela de Natal na vida de quem ",
      "mais precisa. ",      
    ],
  ];

  const [vote, setVote] = useState(false);
  const [current, setCurrent] = useState(0);
  const {
    url,
    setUrl,
    canvasRef,
    downloadRef,
    fromRef,
    toRef,
    setMessage,
    setTo,
    setFrom,
    setMessageIndex,
    voltarCartoes,
    resetCanvas,
    restartCanvas,
  } = useCanvas();
  const [downloadPath, setDownloadPath] = useState(null);
  const [wppUrl, setWppUrl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [startVoting, setStartVoting] = useState(false);
  const shareRef = useRef(null);

  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setWppUrl("whatsapp://send");
    } else {
      setWppUrl("https://wa.me/");
    }
  }, []);

  useEffect(() => {
    const deadline = new Date("2022-11-26 23:59:00");
    const now = new Date();
    console.log(deadline - now < 0);

    if (deadline - now < 0) setStartVoting(true);
  }, []);

  useEffect(() => {
    const deadline = new Date("2022-12-07 23:59:00");
    const now = new Date();
    console.log(deadline - now > 0);

    if (deadline - now < 0) setStartVoting(false);
  }, []);

  useEffect(() => {
    if (current > messages.length - 1 || current < 0) setCurrent(0);
  }, [current]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [vote]);

  function shareCard() {
    if (canvasRef) {
      const canvas = canvasRef.current;
      const shareImage = new Image();
      shareImage.crossOrigin = "Anonymous";

      canvas.toBlob(function (blob) {
        console.log("shareImg", shareImage);
        shareImage.src = URL.createObjectURL(blob);

        shareImage.onload = function () {
          setDownloadPath(shareImage.src);
          URL.revokeObjectURL(url);
        };

        const file = new File([blob], "cartao-cejam.png", { type: blob.type });
        const filesArray = [file];
        console.log(shareImage);

        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              title: "Natal com #TimeCEJAM",
              text: "Natal com #TimeCEJAM",
              files: filesArray,
            })
            .catch((error) => alert("Erro ao compartilhar!"));
        } else if (navigator.share) {
          alert("share");
          navigator.share({
            title: "Natal com #TimeCEJAM",
            text:
              "Feliz Natal com #TimeCEJAM. Veja mais em https://natal.cejam.org.br",
            url: "https://cejam.org.br",
          });
        } else {
          setPopup(true);
        }
      }, "image/png");
    }
  }

  return (
    <section className="content cartoes">
      {/* <div className="heading">
        <h1 className="title">Cartão de Natal personalizado</h1>
        <h3>
          Ho, ho, ho! O Papai Noel pode ser fruto da nossa imaginação, mas um
          cartão de Natal personalizado por você é a mais pura realidade.
        </h3>
      </div>

      <ol>
        <li>Selecione um dos modelos abaixo</li>
        <li>
          Escolha a mensagem do seu cartão clicando na sua favorita abaixo
        </li>
        <li>Insira seu nome e o nome do destinatário e confirme</li>
        <li>Faça o download ou compartilhe o cartão de Natal</li>
      </ol> */}

      <section className="gradient-wrapper">
        {!url ? (
          <>  
            {/* <Snowfall snowflakeCount={30} speed={[0.5, 0.8]} wind={[0.5, 0.5]}/> */}
            <section className="nav-back scale-in-ver-top">
              <div className="btn-back d-flex align-items-center">
                <a href="https://natal.cejam.org.br/" className="d-flex">
                  <span className="arrow left" id="voltar-site"></span>
                  <p className="text-white mb-0 d-flex align-items-center">Voltar</p>
                </a>                
              </div>
            </section>
            <section style={{padding:"4rem 0 2rem 0"}}>
              <h6>Clique no cartão para selecioná-lo:</h6>
            </section>
            <ul className="card-list">
              <li
                className="cards-natal d-flex flex-column m-auto"
                onClick={(e) => setUrl(e.currentTarget.children[1].src)}
              >
                <p className="text-white">Cartão Institucional</p>
                <img src={cartaoCejam} alt="Cartão de Natal do CEJAM" />
              </li>
              <li
                className="card-natal d-flex flex-column m-auto"
                onClick={(e) => {
                  setUrl(e.currentTarget.children[1].src);
                  // setMessageIndex(current);
                }}
              >
                <p className="text-white">Cartão categoria 1 - Lorena da Silva Borges</p>
                <img src={cartao1} alt="Cartão de Natal do CEJAM" />
              </li>
              <li
                className="card-natal d-flex flex-column m-auto"
                onClick={(e) => {
                  setUrl(e.currentTarget.children[1].src);
                  // setMessageIndex(current);
                }}
              >
                <p className="text-white">Cartão categoria 2 - Isabella Marques Freitas</p>
                <img src={cartao2} alt="Cartão de Natal do CEJAM" />
              </li>
              
              {/* - */}
            </ul>
          </>
        ) : (
          <>
            {/* <Snowfall snowflakeCount={30} speed={[0.5, 0.8]} wind={[0.5, 0.5]} /> */}
            <section className="nav-back scale-in-ver-top">
              <div className="btn-back d-flex align-items-center">
                <a onClick={(e) => voltarCartoes()} className="d-flex">
                  <span className="arrow left" id="voltar-site"></span>
                  <p className="text-white mb-0 d-flex align-items-center">Escolha outro modelo de cartão</p>
                </a>                          
              </div>
            </section>
            <section className="card-selection pt-5 slide-in-blurred-left">
              <div className="message">
                <canvas ref={canvasRef} />
                
                <div className="right-groups">
                  <div className="w-100 mt-4 mb-0">
                    <div className="d-flex w-100 position-relative">
                      <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning" id="barra-proresso" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="div-star apagado">
                      </div>
                    </div>
                    <div className="infos-help-cartoes mt-4">
                      <h6 className="text-white info-title-cartao slide-in-blurred-left">Enviar o cartão do CEJAM é muito fácil. Vamos lá? </h6>
                      <p className="info-cartao mb-0 slide-in-blurred-left">Comece preenchendo o campo "De" com o seu nome. Em seguida, clique em "OK". </p>
                    </div>
                  </div>
                  <div className="group-input" id="group-from">
                    <label htmlFor="from">De: </label>
                    <input type="text" id="from" ref={fromRef} />

                    <button
                      type="button"
                      className="ms-1"
                      onClick={(e) =>
                        setFrom(e.currentTarget.previousElementSibling.value)
                      }
                    >
                      <p className="mb-0 p-1">OK</p>
                    </button>
                  </div>

                  <div className="group-input d-none" id="group-to">
                    <label htmlFor="to">Para: </label>
                    <input type="text" id="to" ref={toRef} />
                    <button
                      type="button"
                      className="ms-1"
                      onClick={(e) =>
                        setTo(e.currentTarget.previousElementSibling.value)
                      }
                    >
                      <p className="mb-0 p-1">OK</p>
                    </button>
                  </div>

                  <div className="group d-none" id="group-message">
                    <button
                      className={current == 0 ? "disabled arrow left" : "arrow left"}
                      type="button"
                      disabled={current == 0 ? "disabled" : ""}
                      onClick={() => setCurrent((current) => current - 1)}
                    ></button>

                    {
                      <p
                        className="message"
                        onClick={() => setMessage(messages[current])}
                      >
                        {messages[current]}
                      </p>
                    }                
                    <button
                      className={current == messages.length - 1 ? "disabled arrow right" : "arrow right"}
                      type="button"
                      disabled={current == messages.length - 1 ? "disabled" : ""}
                      onClick={() => setCurrent((current) => current + 1)}
                    ></button>
                  </div>
                  
                  <div className="group-buttons d-none mt-3">
                    <a className="cta" ref={downloadRef} download="cartao-cejam.png">
                      Download
                    </a>
                    <button
                      type="button"
                      onClick={shareCard}
                      className="cta"
                      id="cartao_comp"
                      ref={shareRef}
                    >
                      Compartilhar
                    </button>
                    <button type="button" onClick={restartCanvas} className="d-flex clear w-100 justify-content-center" style={{flex:"1 0 100%", height:"32px"}}>
                      <div className="" style={{width:"32px", height:"32px"}}>
                        <img src={iconRefazer} className="img-fluid" alt="" />
                      </div>
                      <p className="ms-2 mb-0">Refazer</p>
                    </button>
                  </div>
                </div>
              </div>

              <div className={popup ? "fallback-popup show" : "fallback-popup"}>
                <h4>Compartilhar cartão</h4>

                <p>
                  Para compartilhar o seu cartão de Natal personalizado complete
                  os passos abaixo
                </p>
                <ol>
                  <li>Preencha o cartão corretamente</li>
                  <li>
                    Faça o{" "}
                    <a href={downloadPath} download="cartao-CEJAM.png">
                      download
                    </a>{" "}
                    do cartão
                  </li>
                  <li>
                    Compartilhe diretamente via{" "}
                    <a
                      href={`${wppUrl}?text=Boas%20Festas%20com%20%23TimeCEJAM.%20Veja%20mais%20em%20https%3A%2F%2Fnatal.cejam.org.br`}
                    >
                      Whatsapp
                    </a>{" "}
                    ou na sua rede social favorita
                  </li>
                </ol>

                <button className="cta" onClick={() => setPopup(false)}>
                  Fechar
                </button>
              </div>
            </section>
          </>
        )}
      </section>
    </section>
  // ) : startVoting ? (
  //   <Votar />
  // ) : (
  //   <section
  //     className="content"
  //     style={{
  //       textAlign: "center",
  //       padding: "2rem",
  //       height: "calc(70vh - 10rem)",
  //     }}
  //   >
  //     <h3>
  //       Votações encerradas, volte mais tarde para conhecer os vencedores!
  //     </h3>
  //   </section>
  );
}

export default Cartoes;

import { useEffect, useRef, useState } from "react";

export function useCanvas() {
  const canvasRef = useRef(null);
  const downloadRef = useRef(null);
  const [url, setUrl] = useState(null);
  const [message, setMessage] = useState(null);
  const [to, setTo] = useState(null);
  const [from, setFrom] = useState(null);
  const [image, setImage] = useState(null);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const [currentMessage, setMessageIndex] = useState(0);
  const [currentFrom, setFromIndex] = useState(0);
  const [currentTo, setToIndex] = useState(0);
  let call;
  let posX = 0;
  let posY = 0;
  // Usados para realizar os passos
  var groupFrom       = document.querySelector("#group-from");
  var groupTo         = document.querySelector("#group-to");
  var groupMessage    = document.querySelector("#group-message");
  var groupButtons    = document.querySelector(".group-buttons");
  var progressBar     = document.querySelector("#barra-proresso");
  var infoTitle       = document.querySelector(".info-title-cartao");
  var infoDescription = document.querySelector(".info-cartao");
  var alterStar = document.querySelector(".div-star");
  // Usados para realizar os passos

  function fetchUrl(){
    if (canvasRef !== null) {
      const canvas = canvasRef.current;
      const image = new Image();

      image.src = url;
      console.log(url);

      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext("2d").drawImage(image, 0, 0);
      };

      setImage(image);
    }
  }

  useEffect(() => {
    fetchUrl();
  }, [url]);

  function fetchMessage(){
    if (canvasRef !== null && message !== null) {  
      const canvas = canvasRef.current;

      if (url.match("cartao-cejam")) {
        posX = canvas.width *0.1;
        posY = canvas.height / 2;
        message.forEach((msg, index) => {
          canvas.getContext("2d").font = `${canvas.width * 0.022}px Poppins`;
          canvas.getContext("2d").width = `${posX/2}`;
          canvas.getContext("2d").textAlign = "start";
          canvas.getContext("2d").fillStyle = "#ede8d3";
          canvas.getContext("2d").fillText(msg, posX - 30 , posY - 20 + index * 30);
        });
      } else {

        posX = canvas.width / 2;
        posY = canvas.height / 2;
        message.forEach((msg, index) => {
          canvas.getContext("2d").font = `${canvas.width * 0.022}px Poppins`;
          canvas.getContext("2d").width = `${posX/2}`;
          canvas.getContext("2d").textAlign = "start";
          canvas.getContext("2d").fillStyle = "#ede8d3";                       
          canvas.getContext("2d").fillText(msg, posX/20, posY -20 + index * 30);
        });

        // posX = canvas.width / 2 + 120;
        // posY = canvas.height / 2;

        // let newMessage = message.join("");
        // newMessage = newMessage.split(" ");

        // function wrapText(context, x, y, maxWidth, lineHeight) {
        //   var line = "";
        //   for (var n = 0; n < newMessage.length; n++) {
        //     var testLine = line + newMessage[n] + " ";
        //     var metrics = context.measureText(testLine);
        //     var testWidth = metrics.width;
        //     if (testWidth > maxWidth) {
        //       context.font = `${canvas.width * 0.02}px Poppins`;
        //       context.textAlign = "center";
        //       context.fillStyle = "#ede8d3";
        //       context.fillText(line, x + 70, y);
        //       line = newMessage[n] + " ";
        //       y += lineHeight;
        //     } else {
        //       line = testLine;
        //     }
        //   }
        //   context.fillText(line, x + 70, y);
        // }

        // wrapText(canvas.getContext("2d"), posX, posY - 30, 195, 25);
      }
      const src = canvas.toDataURL("image/png");
      downloadRef.current.href = src;
      setMessageIndex(currentMessage + 1);
      groupFrom.classList.add("d-none");
      groupTo.classList.add("d-none");
      groupMessage.classList.add("d-none");
      groupButtons.classList.remove("d-none");
      progressBar.classList.remove('w-30');
      progressBar.classList.remove('w-60');
      progressBar.classList.add('w-100');
      alterStar.classList.remove('apagado');
      alterStar.classList.add('parabens');
      infoTitle.innerHTML = '<span class="text-gratulation">Parabéns!</span><br/>O seu cartão personalizado está pronto.';
      infoDescription.innerHTML = "Escolha se deseja fazer o download ou enviá-lo diretamente pelo WhatsApp."
    }
  }

  useEffect(() => {
    if(currentMessage >= 1 && !call){
      resetCanvas("fetchMessage");
      call = undefined;
    }
    fetchMessage();
  }, [message]);

  function fetchFrom(){
    if (canvasRef !== null) {
      const canvas = canvasRef.current;
      if (from !== "" && from !== null) {
        if (url.match("cartao-cejam")) {
          posX = canvas.width * 0.145 - 10;
          posY = canvas.height - 145;
        } else{
          posX = canvas.width * 0.14 - 10;
          posY = canvas.height - 143;
        } 
        // else {
        //   posX = canvas.width * 0.145 - 10;
        //   posY = canvas.height - 108;
        //   // posX = canvas.width * 0.18 - 37;
        //   // posY = canvas.height - 123;
        // }

        canvas.getContext("2d").font = `${canvas.width * 0.025}px Poppins`;
        canvas.getContext("2d").textAlign = "left";
        canvas.getContext("2d").fillStyle = "#ede8d3";
        canvas.getContext("2d").fillText(from, posX, posY);

        const src = canvas.toDataURL("image/png");
        downloadRef.current.href = src;
        groupFrom.classList.add("disable");
        groupTo.classList.remove("d-none");
        progressBar.classList.add('w-30');
        infoTitle.innerHTML = "Ótimo! Faltam só mais alguns passos.";
        infoDescription.innerHTML = "Em “Para”, escreva o nome da pessoa especial que irá receber seu cartão. Clique em “OK” para confirmar."
      }
    }
    setFromIndex(currentFrom + 1);
  }


  useEffect(() => {
    if(currentFrom >= 1 && !call){
      resetCanvas("fetchFrom");
      call = undefined;
    }
    fetchFrom();
  }, [from]);

  function fetchTo(){
    if (canvasRef !== null) {
      const canvas = canvasRef.current;
      if (to !== "" && to !== null) {
        if (url.match("cartao-cejam")) {
          posX = canvas.width * 0.16;
          posY = canvas.height - 85;
        } else{
          posX = canvas.width * 0.16;
          posY = canvas.height - 85;
        } 
        // else {
        //   posX = canvas.width * 0.16;
        //   posY = canvas.height - 59;
        //   // posX = canvas.width * 0.18 - 22;
        //   // posY = canvas.height - 95;
        // }

        canvas.getContext("2d").font = `${canvas.width * 0.025}px Poppins`;
        canvas.getContext("2d").textAlign = "left";
        canvas.getContext("2d").fillStyle = "#ede8d3";
        canvas.getContext("2d").fillText(to, posX, posY);
        const src = canvas.toDataURL("image/png");
        downloadRef.current.href = src;

        // if(url.match("cartao-cejam")){
          groupTo.classList.add("d-none");
          groupFrom.classList.add("d-none");
          groupMessage.classList.remove("d-none");
          progressBar.classList.remove('w-30');
          progressBar.classList.add('w-60');
          infoTitle.innerHTML = "Agora é hora de escolher sua mensagem preferida.";
          infoDescription.innerHTML = "Clique nas setas para visualizá-las. Depois, clique em cima da mensagem para selecioná-la."
        // }else if(url.match("cartao1")){
        //   groupFrom.classList.add("d-none");
        //   groupTo.classList.add("d-none");
        //   groupMessage.classList.add("d-none");
        //   groupButtons.classList.remove("d-none");
        //   progressBar.classList.remove('w-30');
        //   progressBar.classList.remove('w-60');
        //   progressBar.classList.add('w-100');
        //   alterStar.classList.remove('apagado');
        //   alterStar.classList.add('parabens');
        //   infoTitle.innerHTML = '<span class="text-gratulation">Parabéns!</span><br/>O seu cartão personalizado está pronto.';
        //   infoDescription.innerHTML = "Escolha se deseja fazer o download ou enviá-lo diretamente pelo WhatsApp."
        // }

       
      
      }
      
    }
    setToIndex(currentTo + 1);
  }

  useEffect(() => {
    if(currentTo >= 1 && !call){
      resetCanvas("fetchTo");
      call = undefined;
    }
    fetchTo();
  }, [to]);

  function resetCanvas(par) {
    call = par;
    const canvas = canvasRef.current;
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    canvas.getContext("2d").drawImage(image, 0, 0);
    if(call == "fetchMessage"){
      fetchTo();
      fetchFrom();
    }else if(call == "fetchTo"){
      fetchMessage();
      fetchFrom();
    }else if(call == "fetchFrom"){
      fetchMessage();
      fetchTo();
    }
  }

  function voltarCartoes(e){
    restartCanvas();
    setTimeout(() => {
      setUrl();
    }, 100);
  }

  function restartCanvas() {
    const canvas = canvasRef.current;
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    canvas.getContext("2d").drawImage(image, 0, 0);

    setMessage(null);
    setFrom(null);
    setTo(null);
    fromRef.current.value = "";
    toRef.current.value = "";

    groupTo.classList.add("d-none");
    groupFrom.classList.remove("d-none");
    groupMessage.classList.add("d-none");
    groupButtons.classList.add("d-none");
    progressBar.classList.remove('w-100');
    alterStar.classList.remove('parabens');
    alterStar.classList.add('apagado');
    infoTitle.innerHTML = "Enviar o cartão do CEJAM é muito facil. Vamos lá? ";
    infoDescription.innerHTML = 'Comece preenchendo o campo "De" com o seu nome. Em seguida, clique em "OK".'
  }


  return {
    downloadRef,
    message,
    from,
    fromRef,
    to,
    toRef,
    url,
    setUrl,
    canvasRef,
    setMessage,
    setTo,
    setFrom,
    setMessageIndex,
    resetCanvas,
    voltarCartoes,
    restartCanvas,
  };
}
